<template>
    <span>
         
        <b-form-row align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: normal; font-size: 1.1rem">  
                        period:&nbsp;
                        <b-form-select size="sm" style="border:1px solid gray; width: auto;"
                                       v-model="bollingerPeriod" :options="bollingerPeriodOptions"></b-form-select>

                        <span style="font-weight: normal">  &nbsp;days</span>
                    </b-col>
        </b-form-row>
           
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        data: function () {
            return {
                bollingerPeriodOptions: Array(300).fill(null).map((x, i) => ({value: i + 1, text: i + 1})),
                options: [
                    {value: true, text: "yes"},
                    {value: false, text: "no"}
                ]
            };
        },
        computed: {
            bollingerPeriod: {
                get() {
                    return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "bb").period;
                },
                set(period) {
                    this.$store.commit(this.namespace + '/indicators/set', {indicator: "bb", key: "period", value: period});
                }
            }
        }
    }
</script>
