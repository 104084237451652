<template>
    <span>

        <b-form-row align-h="start" style="border:0px solid gray;  padding: 2px">
            <b-col style="font-weight: normal; font-size: 1rem">  
                period:&nbsp; <b-form-select style="border:1px solid gray; width: auto;" size="sm"
                                             v-model="period" :options="periodOptions"></b-form-select>
                <span style="font-weight: normal">  &nbsp;days</span>

                using &nbsp;<b-form-select :disabled="legs > 1"   style="border:1px solid gray; width: auto;" size="sm"
                                           v-model="source" :options="sourceOptions"></b-form-select>
            </b-col>
        </b-form-row>
        <div v-if="legs == 1" style="text-align: left; padding: 2px"> typical = (high + low + close)/3  </div>

    </span>
</template>

<script>
    module.exports = {
        mounted() {
            console.log("mounted() starting.");
        },
        props: ['namespace'],
        data: function () {
            return {
                periodOptions: Array(19).fill(null).map((x, i) => ({value: i + 2, text: i + 2})),
                options: [
                    {value: true, text: "yes"},
                    {value: false, text: "no"}
                ],
                sourceOptions: [
                    {value: "closes", text: "closes"},
                    {value: "typical", text: "typical"}
                ]
            }
        },
        computed: {
            cciIndicator() {
                return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "cci");
            },
            period: {
                get() {
                    return this.cciIndicator.period;
                },
                set(period) {
                    console.log("period.set(), period=", period);
                    this.$store.commit(this.namespace + '/indicators/set', {indicator: "cci", key: "period", value: period});
                }
            },
            source: {
                get() {
                    return this.$store.state[this.namespace].legs == 1 ? this.cciIndicator.source : "closes";
                },
                set(source) {
                    console.log("source.set(), source=", source);
                    this.$store.commit(this.namespace + '/indicators/set', {indicator: "cci", key: "source", value: source});
                }
            },
            legs() {
                return this.$store.state[this.namespace].legs;
            }
        }
    }
</script>
