<template>
    <span>
                <b-form-row align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: normal; font-size: 1.1rem">  
                        period:&nbsp;
                        <b-form-select style="border:1px solid gray; width: auto; font-size: 12px" size="sm"
                                       v-model="smaLength" :options="smaLengthOptions"></b-form-select>

                        <span style="font-weight: normal">  &nbsp;days</span>
                    </b-col>
                </b-form-row>           
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        data: function () {
            return {
                smaLengthOptions: Array(300).fill(null).map((x, i) => ({value: i + 1, text: i + 1}))
            };
        },
        computed: {
            smaLength: {
                get() {
                    return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "sma").length;
                },
                set(length) {
                    this.$store.commit(this.namespace + '/indicators/set', {indicator: "sma", key: "length", value: length});
                }
            }
        }
    }
</script>
