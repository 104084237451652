<template>
    <span>
       
                    <b-form-row align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: normal; font-size: 1.1rem">  
                            period:&nbsp;
                            <b-form-select style="border:1px solid gray; width: auto;" size="sm"
                                           v-model="period" :options="rsiPeriodOptions"></b-form-select>

                            <span style="font-weight: normal">   &nbsp;days</span>
                        </b-col>
                    </b-form-row>
                                     
    </span>
</template>

<script>
    export default {
        props: ['namespace'],
         data: function () {
            return {
                rsiPeriodOptions: Array(14).fill(null).map((x, i) => ({value: i + 1, text: i + 1})),
            };
        },
        computed: {
             period: {
                get() {
                    return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "rsi").period;
                },
                set(period) {
                    console.log("period.set(), period=", period);
                    this.$store.commit(this.namespace + '/indicators/set', {indicator: "rsi", key: "period", value: period});
                }
            }
        }
    }
</script>
