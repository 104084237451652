<template>
    <div>

      <b-button v-if="program !== 'Calculator'" v-b-modal.period_popup size="sm" style="font-weight: bold; margin: 0 0px 0 0px;
                 border: 1px solid gray; color: black; background-color: #ffdf00">
         period
        </b-button>

         <table v-if="program === 'Calculator'" colspan="2" style="margin: 0 0 2px 0; background-color: #ffdf00;  border: 1px solid gray">   
            <tr>
                <td>
                    <b-form-group label="" v-slot="{ ariaDescribedby }" style="margin: 0 0 -3px 0; font-weight: bold;">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="buySell"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    size="sm"></b-form-radio-group>
                  </b-form-group>
                </td>                
                <td>    
                    <open-close-selector-no-year v-bind:namespace="namespace" style="width: 100%; margin: -2px 0 -2px 0"></open-close-selector-no-year>
                </td>
                </tr>
                </table>
          
 <b-modal v-if="program !== 'Calculator'" ref="period_popup" id="period_popup" header-bg-variant="success"
                 header-text-variant="'light'" ok-only size="md" title="Period">

<table border=0 style="font-weight: normal; border: 0 solid gray; color: black; margin: 0 11px 0 0px;
                     padding: 1px 0 1px 0; width: 100%">
            
            <tr>
                 <td v-if="program !== 'Calculator'" style="padding: 0 0 0 4px">
                    <b-form-checkbox
                         v-model="showTradingPeriod"
                         name="checkbox-1"
                         :value="true"
                         :unchecked-value="false">
                          show period
                    </b-form-checkbox>
                </td>
            </tr>
            <tr>
            <tr>
                <td style="text-align: left;">
                 <!--   <b-form-group label="" v-slot="{ ariaDescribedby }" v-if="showTradingPeriod || showPlaybackControl || program === 'Calculator'"
                            style="margin: 20px 1px 2px 5px; font-weight: bold;"> -->
                  <b-form-group label="" v-slot="{ ariaDescribedby }" style="margin: 20px 1px 20px 5px; font-weight: bold;">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="buySell"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    size="sm"></b-form-radio-group>
                  </b-form-group>
                </td>
               
            </tr>
        <!--  <tr v-if="showTradingPeriod || showPlaybackControl || program === 'Calculator'"> -->
            <tr>
                <td v-if="program !== 'Calculator'" colspan="2" style="padding: 3px 2px 0px 2px">       
                    <open-close-selector v-bind:namespace="namespace" style=""></open-close-selector>
                </td>
            </tr>
        </table>

 </b-modal>

    </div>
</template>

<style scoped>
table {
    border-collapse: separate;
    border-radius: 3px;
}
</style>

<script>
    import openCloseSelector from '@/components/open-close-selector';
    import openCloseSelectorNoYear from '@/components/open-close-selector-no-year';

    export default {
        components: {
            openCloseSelector, openCloseSelectorNoYear
        },
        mounted() {
          // console.log("trade-specs.vue mounted() starting.");
           // console.log("this.namespace=", this.namespace);
        },
        props: ['namespace', 'title'],
        data() {
            return {
                selected: 'first',
                options: [
                    {text: 'buy', value: 'buy'},
                    {text: 'sell', value: 'sell'}
                ]
            }
        },
        computed: {
            program(){
                return this.$store.state[this.namespace].program;
            },
            buySell: {
                get() {
                    return this.$store.state[this.namespace].buySell;
                },
                set(buySell) {
                    this.$store.commit(this.namespace + '/setBuySell', buySell);
                }
            },
            showTradingPeriod: {
                get(){
                    return this.$store.state[this.namespace].showTradingPeriod;
                },
                set(showTradingPeriod){
                    this.$store.commit(this.namespace + '/setShowTradingPeriod', showTradingPeriod);
                }
            },
            showPlaybackControl(){
                return this.$store.state[this.namespace].showPlaybackControl;
            }
        }
    }
</script>