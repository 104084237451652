<template>
    <span>
        <b-form-row align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: normal; font-size: 1.1rem">  
                         number of columns:&nbsp;
                <b-form-select style="border:1px solid gray; width: auto;  font-weight: normal" size="sm"
                               v-model="volumeProfileColumns" :options="volumeProfileColumnsOptions"></b-form-select>
                    </b-col>
        </b-form-row>                                       
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        data: function () {
            return {
                options: [
                    {value: true, text: "yes"},
                    {value: false, text: "no"}
                ],
                volumeProfileColumnsOptions: Array(100).fill(null).map((x, i) => ({value: i + 1, text: i + 1})),
            };
        },
        computed: {
            volumeProfileColumns: {
                get() {
                    return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "vp").columns;
                },
                set(columns) {
                    this.$store.commit(this.namespace + '/indicators/set', {indicator: "vp", key: "columns", value: columns});
                }
            }
        }
    }
</script>
